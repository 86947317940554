import Vue from 'vue'
import VueApollo from 'vue-apollo'
import 'cross-fetch/polyfill'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client/src'
import Cookie from 'universal-cookie'
import { InMemoryCache } from 'apollo-cache-inmemory'

Vue.use(VueApollo)

export default (ctx, inject) => {
  const providerOptions = { clients: {} }
  const { app, beforeNuxtRender, req } = ctx
  const AUTH_TOKEN_NAME = 'apollo-token'
  const COOKIE_ATTRIBUTES = {"expires":7,"path":"\u002F","secure":false}
  const AUTH_TYPE = 'Bearer '
  const cookies = new Cookie(req && req.headers.cookie)

  // Config

      const defaultTokenName = ''  || AUTH_TOKEN_NAME

      function defaultGetAuth () {
        const token = cookies.get(defaultTokenName)
        return token && defaultClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defaultClientConfig

        defaultClientConfig = require('@/plugins/apollo-default-config.js')

        if ('default' in defaultClientConfig) {
          defaultClientConfig = defaultClientConfig.default
        }

        defaultClientConfig = defaultClientConfig(ctx)

      const defaultValidateToken = () => true

      if (!defaultClientConfig.validateToken) {
        defaultClientConfig.validateToken = defaultValidateToken
      }

      const defaultCache = defaultClientConfig.cache
        ? defaultClientConfig.cache
        : new InMemoryCache(defaultClientConfig.inMemoryCacheOptions ? defaultClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defaultCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defaultClient : null)
      }

      if (!defaultClientConfig.getAuth) {
        defaultClientConfig.getAuth = defaultGetAuth
      }

      if (process.client && defaultClientConfig.browserHttpEndpoint) {
        defaultClientConfig.httpEndpoint = defaultClientConfig.browserHttpEndpoint
      }

      defaultClientConfig.ssr = !!process.server
      defaultClientConfig.cache = defaultCache
      defaultClientConfig.tokenName = defaultTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defaultClientConfig.httpLinkOptions) {
          defaultClientConfig.httpLinkOptions = {}
        }
        if (!defaultClientConfig.httpLinkOptions.headers) {
          defaultClientConfig.httpLinkOptions.headers = {}
        }
        defaultClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defaultApolloCreation = createApolloClient({
        ...defaultClientConfig
      })
      defaultApolloCreation.apolloClient.wsClient = defaultApolloCreation.wsClient

          providerOptions.defaultClient = defaultApolloCreation.apolloClient

      const applicationTokenName = ''  || AUTH_TOKEN_NAME

      function applicationGetAuth () {
        const token = cookies.get(applicationTokenName)
        return token && applicationClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let applicationClientConfig

        applicationClientConfig = require('~/plugins/apollo-application-config.js')

        if ('default' in applicationClientConfig) {
          applicationClientConfig = applicationClientConfig.default
        }

        applicationClientConfig = applicationClientConfig(ctx)

      const applicationValidateToken = () => true

      if (!applicationClientConfig.validateToken) {
        applicationClientConfig.validateToken = applicationValidateToken
      }

      const applicationCache = applicationClientConfig.cache
        ? applicationClientConfig.cache
        : new InMemoryCache(applicationClientConfig.inMemoryCacheOptions ? applicationClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        applicationCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.application : null)
      }

      if (!applicationClientConfig.getAuth) {
        applicationClientConfig.getAuth = applicationGetAuth
      }

      if (process.client && applicationClientConfig.browserHttpEndpoint) {
        applicationClientConfig.httpEndpoint = applicationClientConfig.browserHttpEndpoint
      }

      applicationClientConfig.ssr = !!process.server
      applicationClientConfig.cache = applicationCache
      applicationClientConfig.tokenName = applicationTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!applicationClientConfig.httpLinkOptions) {
          applicationClientConfig.httpLinkOptions = {}
        }
        if (!applicationClientConfig.httpLinkOptions.headers) {
          applicationClientConfig.httpLinkOptions.headers = {}
        }
        applicationClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let applicationApolloCreation = createApolloClient({
        ...applicationClientConfig
      })
      applicationApolloCreation.apolloClient.wsClient = applicationApolloCreation.wsClient

          providerOptions.clients.application = applicationApolloCreation.apolloClient

      const authTokenName = ''  || AUTH_TOKEN_NAME

      function authGetAuth () {
        const token = cookies.get(authTokenName)
        return token && authClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let authClientConfig

        authClientConfig = require('~/plugins/apollo-auth-config.js')

        if ('default' in authClientConfig) {
          authClientConfig = authClientConfig.default
        }

        authClientConfig = authClientConfig(ctx)

      const authValidateToken = () => true

      if (!authClientConfig.validateToken) {
        authClientConfig.validateToken = authValidateToken
      }

      const authCache = authClientConfig.cache
        ? authClientConfig.cache
        : new InMemoryCache(authClientConfig.inMemoryCacheOptions ? authClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        authCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.auth : null)
      }

      if (!authClientConfig.getAuth) {
        authClientConfig.getAuth = authGetAuth
      }

      if (process.client && authClientConfig.browserHttpEndpoint) {
        authClientConfig.httpEndpoint = authClientConfig.browserHttpEndpoint
      }

      authClientConfig.ssr = !!process.server
      authClientConfig.cache = authCache
      authClientConfig.tokenName = authTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!authClientConfig.httpLinkOptions) {
          authClientConfig.httpLinkOptions = {}
        }
        if (!authClientConfig.httpLinkOptions.headers) {
          authClientConfig.httpLinkOptions.headers = {}
        }
        authClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let authApolloCreation = createApolloClient({
        ...authClientConfig
      })
      authApolloCreation.apolloClient.wsClient = authApolloCreation.wsClient

          providerOptions.clients.auth = authApolloCreation.apolloClient

      const paymentsTokenName = ''  || AUTH_TOKEN_NAME

      function paymentsGetAuth () {
        const token = cookies.get(paymentsTokenName)
        return token && paymentsClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let paymentsClientConfig

        paymentsClientConfig = require('~/plugins/apollo-payment-config.js')

        if ('default' in paymentsClientConfig) {
          paymentsClientConfig = paymentsClientConfig.default
        }

        paymentsClientConfig = paymentsClientConfig(ctx)

      const paymentsValidateToken = () => true

      if (!paymentsClientConfig.validateToken) {
        paymentsClientConfig.validateToken = paymentsValidateToken
      }

      const paymentsCache = paymentsClientConfig.cache
        ? paymentsClientConfig.cache
        : new InMemoryCache(paymentsClientConfig.inMemoryCacheOptions ? paymentsClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        paymentsCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.payments : null)
      }

      if (!paymentsClientConfig.getAuth) {
        paymentsClientConfig.getAuth = paymentsGetAuth
      }

      if (process.client && paymentsClientConfig.browserHttpEndpoint) {
        paymentsClientConfig.httpEndpoint = paymentsClientConfig.browserHttpEndpoint
      }

      paymentsClientConfig.ssr = !!process.server
      paymentsClientConfig.cache = paymentsCache
      paymentsClientConfig.tokenName = paymentsTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!paymentsClientConfig.httpLinkOptions) {
          paymentsClientConfig.httpLinkOptions = {}
        }
        if (!paymentsClientConfig.httpLinkOptions.headers) {
          paymentsClientConfig.httpLinkOptions.headers = {}
        }
        paymentsClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let paymentsApolloCreation = createApolloClient({
        ...paymentsClientConfig
      })
      paymentsApolloCreation.apolloClient.wsClient = paymentsApolloCreation.wsClient

          providerOptions.clients.payments = paymentsApolloCreation.apolloClient

      const cmsTokenName = ''  || AUTH_TOKEN_NAME

      function cmsGetAuth () {
        const token = cookies.get(cmsTokenName)
        return token && cmsClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let cmsClientConfig

        cmsClientConfig = require('~/plugins/apollo-cms-config.js')

        if ('default' in cmsClientConfig) {
          cmsClientConfig = cmsClientConfig.default
        }

        cmsClientConfig = cmsClientConfig(ctx)

      const cmsValidateToken = () => true

      if (!cmsClientConfig.validateToken) {
        cmsClientConfig.validateToken = cmsValidateToken
      }

      const cmsCache = cmsClientConfig.cache
        ? cmsClientConfig.cache
        : new InMemoryCache(cmsClientConfig.inMemoryCacheOptions ? cmsClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        cmsCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.cms : null)
      }

      if (!cmsClientConfig.getAuth) {
        cmsClientConfig.getAuth = cmsGetAuth
      }

      if (process.client && cmsClientConfig.browserHttpEndpoint) {
        cmsClientConfig.httpEndpoint = cmsClientConfig.browserHttpEndpoint
      }

      cmsClientConfig.ssr = !!process.server
      cmsClientConfig.cache = cmsCache
      cmsClientConfig.tokenName = cmsTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!cmsClientConfig.httpLinkOptions) {
          cmsClientConfig.httpLinkOptions = {}
        }
        if (!cmsClientConfig.httpLinkOptions.headers) {
          cmsClientConfig.httpLinkOptions.headers = {}
        }
        cmsClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let cmsApolloCreation = createApolloClient({
        ...cmsClientConfig
      })
      cmsApolloCreation.apolloClient.wsClient = cmsApolloCreation.wsClient

          providerOptions.clients.cms = cmsApolloCreation.apolloClient

  const vueApolloOptions = Object.assign(providerOptions, {
      errorHandler (error) {
          console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
      }
  })

  const apolloProvider = new VueApollo(vueApolloOptions)
  // Allow access to the provider in the context
  app.apolloProvider = apolloProvider

  if (process.server) {
    const ApolloSSR = require('vue-apollo/ssr')
    beforeNuxtRender(({ nuxtState }) => {
      nuxtState.apollo = ApolloSSR.getStates(apolloProvider)
    })
  }

  inject('apolloHelpers', {
    onLogin: async (token, apolloClient = apolloProvider.defaultClient, cookieAttributes = COOKIE_ATTRIBUTES, skipResetStore = false) => {
      // Fallback for tokenExpires param
      if (typeof cookieAttributes === 'number') cookieAttributes = { expires: cookieAttributes }

      if (typeof cookieAttributes.expires === 'number') {
        cookieAttributes.expires = new Date(Date.now()+ 86400*1000*cookieAttributes.expires)
      }

      if (token) {
        cookies.set(AUTH_TOKEN_NAME, token, cookieAttributes)
      } else {
        cookies.remove(AUTH_TOKEN_NAME, cookieAttributes)
      }
      if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
      if (!skipResetStore) {
        try {
          await apolloClient.resetStore()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('%cError on cache reset (setToken)', 'color: orange;', e.message)
        }
      }
    },
    onLogout: async (apolloClient = apolloProvider.defaultClient, skipResetStore = false) => {
      cookies.remove(AUTH_TOKEN_NAME, COOKIE_ATTRIBUTES)
      if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
      if (!skipResetStore) {
        try {
          await apolloClient.resetStore()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
        }
      }
    },
    getToken: (tokenName = AUTH_TOKEN_NAME) => {
      return cookies.get(tokenName)
    }
  })
}

import { MEMBERSHIP_STATUS } from '~/constants';
import {
  AVAILABLE_PLANS,
  CANCEL_SUBSCRIPTION,
  OWNER_RECEIVED_PAYMENTS_BY_SUBSCRIBER_ID,
} from '~/graphql/payment';

export default {
  async getCustomerInvoices({ commit, dispatch }, subscriberId) {
    const client = this.app.apolloProvider.clients.payments;
    commit('setLoading', true);
    try {
      const { data } = await client.query({
        query: OWNER_RECEIVED_PAYMENTS_BY_SUBSCRIBER_ID,
        fetchPolicy: 'no-cache',
        variables: {
          subscriberId,
        },
      });
      if (!data) {
        throw Error('No data');
      }
      commit('setCustomerInvoices', data?.ownerReceivedPaymentsBySubscriberId);
    } catch (e) {
      dispatch(
        'handleError',
        {
          e,
          queryName: 'OWNER_RECEIVED_PAYMENTS_BY_SUBSCRIBER_ID',
          variables: subscriberId,
        },
        { root: true },
      );
    } finally {
      commit('setLoading', false);
    }
  },
  async getAvailablePlans({ commit, dispatch }) {
    commit('setLoading', true);
    const client = this.app.apolloProvider.clients.payments;
    try {
      const { data } = await client.query({
        query: AVAILABLE_PLANS,
      });
      if (!data.availablePlans.length) {
        throw Error('');
      }
      commit('setAvailablePlans', data?.availablePlans);
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'AVAILABLE_PLANS' },
        { root: true },
      );
    } finally {
      commit('setLoading', false);
    }
  },
  async cancelSubscription({ commit, dispatch }, variables) {
    commit('setLoading', true);
    const { userId, productId, id } = variables;
    const client = this.app.apolloProvider.clients.payments;
    try {
      const { data } = await client.mutate({
        mutation: CANCEL_SUBSCRIPTION,
        variables: {
          userId,
          productId,
        },
      });
      if (data.cancelSubscription?.errors?.length) {
        throw Error(data.cancelSubscription.errors[0]);
      }
      dispatch(
        'organization/updateMembership',
        {
          id,
          membershipStatus: MEMBERSHIP_STATUS.EXPIRED,
          membershipExpireAt: Date.now().toString(),
        },
        { root: true },
      );
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'CANCEL_SUBSCRIPTION' },
        { root: true },
      );
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  setUser(state, payload) {
    state.user = payload.user;
    state.roles = payload.roles;
    state.startUrl = payload.startUrl;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setUserData(state, payload) {
    state.user = payload;
  },
};

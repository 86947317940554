export function getStorageUrl(fileName) {
  if (fileName) {
    return process.env.GOOGLE_STORAGE_URL + '/' + fileName;
  }
  return '';
}

export function getIconName(iconName) {
  if (iconName) {
    if (iconName.includes(' ')) {
      return iconName.split(' ');
    }
    return iconName;
  }
  return '';
}

const iconsColors = {
  'globe-europe': '#2562FF',
  'fab youtube': '#FF0000',
  'fab instagram': '#D44299',
  'fab facebook': '#4267B2',
  'fab facebook-messenger': '#00B2FF',
  'fab tiktok': '',
  'fab twitch': '#6441A5',
  'fab snapchat-ghost': '',
  'fab telegram-plane': '#0088CC',
  'fab pinterest': '#F0002A',
  'fab linkedin-in': '#006192',
  'fab xing': '#026466',
  'fab twitter': '#1DA1F2',
  'fab spotify': '#1DB954',
  'fab discord': '#738ADB',
  'fab apple': '#738ADB',
  bookmark: '#2562FF',
  wallet: '#2562FF',
  'shopping-cart': '#2562FF',
};
export function getIconStyles(iconName) {
  const color = iconsColors[iconName] || '';
  return {
    color,
  };
}

export function createExternalLink(url) {
  if (
    url.startsWith('http') ||
    url.startsWith('tel') ||
    url.startsWith('mailto')
  ) {
    return url;
  }
  return `http://${url}`;
}

export function getBackgroundPhotoString(url) {
  const cardGradient =
    'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 102.55%)';
  return `${cardGradient}, url(${getStorageUrl(url)}) center`;
}

export async function fetchProfileCustomTheme(name) {
  const id = name.split('-')[1];
  const response = await fetch(`${process.env.REST_URL}/files/theme/${id}`);
  const styles = await response.text();
  const styleElement = document.createElement('style');
  if (
    typeof styleElement === 'object' &&
    styleElement !== null &&
    'setAttribute' in styleElement
  ) {
    styleElement.setAttribute('type', 'text/css');
    styleElement.setAttribute('custom-theme', '');
    styleElement.innerHTML = styles;
  }
  document.querySelector('head').appendChild(styleElement);
}

export default {
  payments(state) {
    return state.customerInvoices;
  },
  loading(state) {
    return state.loading;
  },
  availablePlans(state) {
    return state.availablePlans;
  },
};

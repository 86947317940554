import { extend, ValidationProvider } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend('message', {
  validate(value) {
    return value.length <= 350;
  },
});
extend('futureDate', {
  validate(value) {
    return Date.parse(value) + 86400000 >= new Date(); // 86400000 === 24 hours
  },
});
extend('passwordRules', {
  validate(value) {
    const expression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return value.match(expression);
  },
});

export default {
  components: {
    ValidationProvider,
  },
};

function checkAffiliateLinks({ app, route }) {
  const value = route.query?.af;
  if (!value) return;
  app.$nuxtCookies.set('t2l_Af', value, {
    path: '/',
    maxAge: 60 * 60 * 24 * 30, // 30 days
  });
}

export default checkAffiliateLinks;

import { mapActions } from 'vuex';

export default {
  async created() {
    if (this.$apolloHelpers.getToken()) {
      await this.fetchUserProfiles();
    }
  },
  methods: {
    ...mapActions('profile', ['fetchUserProfiles']),
  },
};

import { CUSTOM_LINK_TYPE, PROFILE_STATUS } from '~/constants';

export default {
  loading(state) {
    return state.loading;
  },
  company(state) {
    return state.company;
  },
  membershipTypes(state) {
    return state.membershipTypes;
  },
  members(state) {
    return state.members.map(
      ({
        membershipStatus,
        membershipType,
        membershipExpireAt,
        id,
        member,
        createdAt,
      }) => {
        return {
          name: member.name,
          iconName: membershipType?.iconName ?? '',
          membershipName: membershipType?.membershipName ?? '',
          guestAllowed: membershipType?.guestAllowed ?? 1,
          membershipId: membershipType?.id ?? '',
          membershipStatus,
          membershipExpireAt,
          createdAt,
          ...member,
          id,
          memberId: member.id,
          nickName: member.nickName,
        };
      },
    );
  },
  member(state, _, rootState) {
    const id = rootState.profile.currentProfileOrganizations[0]?.member?.id;
    return state.members
      .filter((item) => item.member.id === id)
      .map(
        ({
          createdAt,
          id,
          member,
          membershipExpireAt,
          membershipStatus,
          membershipType,
        }) => {
          return {
            ...member,
            createdAt,
            guestAllowed: membershipType?.guestAllowed ?? 1,
            iconName: membershipType?.iconName ?? '',
            id,
            memberId: member.id,
            membershipExpireAt,
            membershipIcon: membershipType?.iconName ?? '',
            membershipId: membershipType?.id ?? '',
            membershipName: membershipType?.membershipName ?? '',
            membershipStatus,
            name: member.name,
          };
        },
      );
  },
  getMembershipTypeByUserId(state, getters) {
    return function (id) {
      return getters.members.find((member) => {
        return member.id === id;
      });
    };
  },
  hasProducts(state) {
    return !!state.products?.length;
  },
  numberOfMembers(state) {
    return state.numberOfMembers;
  },
  organizationProfile(state) {
    return state.organizationProfile;
  },
  organizationCustomLinks(state) {
    if (!state.organizationProfile?.customLinks) return [];
    return state.organizationProfile.customLinks.filter(
      (link) =>
        link.groupType === CUSTOM_LINK_TYPE.CUSTOM_LINK ||
        link.groupType === CUSTOM_LINK_TYPE.TILE,
    );
  },
  organizationHighlighted(state) {
    if (!state.organizationProfile?.customLinks) return [];
    return state.organizationProfile?.customLinks.filter(
      (link) => link.groupType === CUSTOM_LINK_TYPE.TILE,
    );
  },
  organizationContactLinks(state) {
    if (!state.organizationProfile?.customLinks) return [];
    return state.organizationProfile.customLinks.filter(
      (link) =>
        link.groupType === CUSTOM_LINK_TYPE.CONTACT &&
        link.linkStatus === PROFILE_STATUS.PUBLIC,
    );
  },
  organizationPrivateContactLinks(state) {
    if (!state.organizationProfile?.customLinks) return [];
    return state.organizationProfile.customLinks.filter(
      (link) => link.groupType === CUSTOM_LINK_TYPE.CONTACT,
    );
  },
  products(state) {
    return state.products.map(({ member, id }) => {
      return {
        ...member,
        id,
        identifier_int: member.cards[0]?.identifier_int ?? '',
        memberId: member.id,
        name: member?.name ?? '',
      };
    });
  },
};

export default () => ({
  currentProfileOrganizations: [],
  staffSettings: {},
  imageBase64: '',
  organizations: [],
  profile: null,
  qrCodeDialog: false,
  userProfiles: [],
  userProfilesOrganizations: [],
});

import gql from 'graphql-tag';

export const FIND_PROFILE_BY = gql`
  query FIND_PROFILE_BY(
    $profileUniqueValue: String!
    $organizationUniqueValue: String
    $scope: Boolean
    $privateCustomLinks: Boolean
  ) {
    findProfileBy(
      profileUniqueValue: $profileUniqueValue
      organizationUniqueValue: $organizationUniqueValue
      privateCustomLinks: $privateCustomLinks
      scope: $scope
    ) {
      id
      profileType
      profileStatus
      nickName
      name
      descriptionTitle
      description
      photoName
      backgroundPhotoName
      position
      websiteUrl
      cards {
        id
        identifier_int
      }
      customLinks {
        groupType
        contactType
        id
        link
        linkStatus
        iconName
        iconColor
        description
        photoName
        order
      }
    }
  }
`;

export const FIND_CARD_BY_IDENTIFIER = gql`
  query FIND_CARD_BY_IDENTIFIER($identifier_int: Float!) {
    findCardByIdentifier(identifier_int: $identifier_int) {
      id
      identifier
      identifier_int
      locked
      title
      cardDesign {
        id
        name
      }
      invitation {
        status
        period
        id
        isPaid
      }
      profile {
        profileStatus
        id
        nickName
      }
      organization {
        id
        profile {
          id
          nickName
        }
      }
    }
  }
`;

export const FIND_CARD_BY_QR_CODE = gql`
  query FIND_CARD_BY_QR_CODE($qrCodeUrl: String!) {
    findCardByQrCode(qrCodeUrl: $qrCodeUrl) {
      id
      identifier
      identifier_int
      locked
      title
      qrCodeUrl
    }
  }
`;

export const USER_PROFILES = gql`
  query USER_PROFILES {
    userProfiles {
      profileType
      profileStatus
      cards {
        id
        identifier_int
      }
      customLinks {
        contactType
        description
        groupType
        iconColor
        iconName
        id
        link
        linkStatus
        order
        photoName
      }
      id
      nickName
      name
      position
      descriptionTitle
      description
      websiteUrl
      photoName
      backgroundPhotoName
    }
  }
`;

export const FIND_ALL_MEMBERS = gql`
  query findAllMembers($profileId: String!) {
    findAllMembers(profileId: $profileId) {
      id
      membershipStatus
      membershipExpireAt
      createdAt
      membershipType {
        id
        iconName
        membershipName
        guestAllowed
      }
      member {
        name
        id
        photoName
        profileType
        profileStatus
        position
        nickName
        account {
          personIdentifier
        }
      }
    }
  }
`;

export const USER_ORGANIZATIONS = gql`
  query USER_ORGANIZATIONS {
    userOrganizations {
      id
      role
      organization {
        id
        profile {
          id
          profileType
          profileStatus
          backgroundPhotoName
          name
          descriptionTitle
          description
          nickName
          photoName
          position
        }
        membershipTypes {
          iconName
          membershipName
        }
        relatedOrganization {
          id
        }
        hubspot {
          id
          status
        }
      }
    }
  }
`;

export const FIND_ALL_PROFILE_ORGANIZATION = gql`
  query FIND_ALL_PROFILE_ORGANIZATION($profileIds: [String!]!) {
    findAllProfileOrganization(profileIds: $profileIds) {
      membershipStatus
      membershipExpireAt
      membershipType {
        iconName
        id
        membershipName
      }
      id
      member {
        backgroundPhotoName
        createdAt
        description
        descriptionTitle
        id
        name
        nickName
        photoName
        position
        profileStatus
        profileType
        websiteUrl
      }
      organization {
        id
        hubspot {
          accessToken
          contactEmail
          createdAt
          id
          refreshToken
          updatedAt
        }
        settings
        profile {
          backgroundPhotoName
          name
          description
          descriptionTitle
          id
          nickName
          photoName
          profileStatus
          profileType
        }
      }
      createdAt
    }
  }
`;

export const ADD_CARD_TO_PROFILE = gql`
  mutation addCardToProfile($identifier_int: Float!, $profileId: String!) {
    addCardToProfile(identifier_int: $identifier_int, profileId: $profileId) {
      id
      profile {
        id
      }
    }
  }
`;

export const GET_MEMBERS_COUNT = gql`
  query getMembersCount($uniqueValue: String!) {
    getMembersCount(uniqueValue: $uniqueValue)
  }
`;

export const FIND_ALL_MEMBERSHIP_TYPE = gql`
  query findAllMembershipType($profileId: String!) {
    findAllMembershipType(profileId: $profileId) {
      icon: iconName
      id
      text: membershipName
    }
  }
`;

export const ACCOUNT = gql`
  query account {
    account {
      id
      personIdentifier
      photoName
    }
  }
`;

export const GET_IMAGE_BASE64 = gql`
  query getImageBase64($fileName: String!) {
    getImageBase64(fileName: $fileName)
  }
`;

export const FIND_ALL_PRODUCTS = gql`
  query findAllProducts(
    $organizationUniqueValue: String!
    $checkRelatedOrganization: Boolean
  ) {
    findAllProducts(
      organizationUniqueValue: $organizationUniqueValue
      checkRelatedOrganization: $checkRelatedOrganization
    ) {
      id
      member {
        cards {
          id
          identifier
          identifier_int
        }
        name
        id
        nickName
        photoName
        position
        profileStatus
        profileType
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GET_ORGANIZATION($profileId: String!) {
    getOrganization(profileId: $profileId) {
      id
      membershipTypes {
        membershipName
        iconName
      }
      profile {
        id
        profileType
        profileStatus
        position
        nickName
        name
        photoName
        backgroundPhotoName
      }
      settings
    }
  }
`;

export const GET_RELATED_ORGANIZATION = gql`
  query GET_RELATED_ORGANIZATION($organizationId: String!) {
    getRelatedOrganization(organizationId: $organizationId) {
      id
      relatedOrganization {
        id
      }
    }
  }
`;

export const IS_PROFILE_SAVED = gql`
  query IS_PROFILE_SAVED($profileId: String!) {
    isProfileSaved(profileId: $profileId)
  }
`;

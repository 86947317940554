const middleware = {}

middleware['blogRedirect'] = require('../middleware/blogRedirect.js')
middleware['blogRedirect'] = middleware['blogRedirect'].default || middleware['blogRedirect']

middleware['checkAffiliateLinks'] = require('../middleware/checkAffiliateLinks.js')
middleware['checkAffiliateLinks'] = middleware['checkAffiliateLinks'].default || middleware['checkAffiliateLinks']

middleware['isAuth'] = require('../middleware/isAuth.js')
middleware['isAuth'] = middleware['isAuth'].default || middleware['isAuth']

middleware['redirectToWebflow'] = require('../middleware/redirectToWebflow.js')
middleware['redirectToWebflow'] = middleware['redirectToWebflow'].default || middleware['redirectToWebflow']

export default middleware

export { COMPANY } from './company';
export { ICONS } from './fontAwesome';
export { PRICING } from './pricing';

export const PROFILE_TYPE = {
  COMPANY: 'COMPANY',
  PERSONAL: 'PERSONAL',
  PRODUCT: 'PRODUCT',
  CLUB: 'CLUB',
};

export const CUSTOM_LINK_TYPE = {
  TILE: 'TILE',
  CUSTOM_LINK: 'CUSTOM_LINK',
  CONTACT: 'CONTACT',
};

export const INVITATION_STATUS = {
  WAITING: 'WAITING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
};

export const CONTACT_LINK_TYPES = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  MOBILE: 'MOBILE',
  ADDRESS: 'ADDRESS',
  UNSET: 'UNSET',
};

export const MEMBERSHIP_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  INVITED: 'INVITED',
};

export const COOKIE_TYPE = {
  CARD_CONNECT_PRODUCT: 'card-connect-product',
  CARD_CONNECT_RESPONSE: 'card-connect-response',
  CARD_CONNECT_SCANNER: 'card-connect-scanner',
  CLUB_EVENT_RESPONSE: 'club-event-response',
  CLUB_EVENT_SCANNER: 'club-event-scanner',
  NEW_CARD_ID: 'new-card-id',
  NEW_PRODUCT_CARD_ID: 'new-product-card-id',
  PREPAID_CARD_ID: 'prepaid-card-id',
  REDIRECT_CARD_ID: 'redirect-card-id',
};

export const PROFILE_STATUS = {
  PUBLIC: 'PUBLIC',
  NOT_PUBLIC: 'NOT_PUBLIC',
  DELETED: 'DELETED',
  UNACTIVE: 'UNACTIVE',
};

export const MEMBERSHIP_STEP = {
  STATUS: 'MembershipStatus',
  PAYMENT: 'SubscriptionList',
};

export const ADMIN_ROLE = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  CHECKIN: 'CHECKIN',
};

export const HUBSPOT_INTERGRATION_STATUS = {
  CONNECTED: 'CONNECTED',
  EXPIRED: 'EXPIRED',
  CONNECTING: 'CONNECTING',
};
export const HUBSPOT_INTEGRATION_SUCCESS = 'hubspot-integration-success';

export const PRIVACY_POLICY = 'PrivacyPolicy';
export const TERMS_OF_USE = 'TermsOfUse';

export const MEMBER_TITLE = {
  WORK_POSITION: 'Work position',
  ROLE: 'Role',
};

export const TOAST_TYPE = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
};

export const ABOUT_SECTION = {
  COMPANY_PROFILE: 'Company profile',
  PERSONALIZED_TEXT: 'Personalized text',
};

export const WEBSITE_URL = 'websiteUrl';
export const PERSONALIZED_BG = 'personalizedBg';
export const PERSONALIZED_PIC = 'personalizedPic';

export const CUSTOM_INVITATION = 'custom-invitation';
export const COMPANY_INVITATION = 'company-invitation';
export const CUSTOM_THEME = 'custom-theme';
export const CLUB_MEMBERSHIP = 'club-membership';
export const CUSTOM_URL = 'custom-url';
export const HUBSPOT_URL = {
  CONFIRM_INTEGRATION: `${process.env.REST_URL}/hubspot/confirm`,
  SAVE_HUBSPOT_EVENT: `${process.env.baseUrl}/api/hubspot/event`,
};

export const HUBSPOT_EVENT = {
  PRODUCT_REQUEST_CONTACT: 'PRODUCT_REQUEST_CONTACT',
  PRODUCT_REQUEST_DETAILS: 'PRODUCT_REQUEST_DETAILS',
  EVENT_SIGNUP: 'EVENT_SIGNUP',
  PRODUCT_PAGE_VISIT: 'PRODUCT_PAGE_VISIT',
};

export const MOERSCHEN = {
  CLUB_ID: 'ff325929-6eaf-4b03-90fe-7410c279c979',
  CLUB_PROFILE_ID: 'c54c5a7f-0a76-418c-abe5-2bc7154e2059',
  COMPANY_ID: '0a20352b-5b64-4cbb-bc9e-3ea6e32a5d0e',
  COMPANY_PROFILE_ID: 'c45b2952-7087-4447-a3ab-c35598ae18ce',
  INVITATION_ID: '6aa82dee-90f1-473f-a0df-c5d35686e008',
  NAME: 'moerschen',
};

export const REQUEST_STEPS = {
  CONTACT: 'ContactRequest',
  SUCCESS: 'SuccessfulRequest',
};

export const SHOPIFY_BUTTONS_URL =
  'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

export const RHEINFIRE_QR_REDIRECTION = [
  '7367381311756890',
  '4168029409350660',
  '5960674702151290',
  '4022840344964330',
  '7254779601826840',
  '2332923052321530',
  '6625134767215580',
  '1311697627325740',
  '3067851587734560',
  '3597172207739120',
];

export const CUSTOM_LINKS = 'CustomLinks';
export const HIGHLIGHTED = 'Highlighted';
export const RHEIN_FIRE = 'rheinfire';

export const CUSTOM_THEME_ID = {
  T2LOVE: 'df8c144a-9c3d-46e5-90a9-136d15ad1e3f',
  THE_PARADISE_NOW: '9041a6cf-9ec5-4404-b591-9a6b055b6aa7',
  THE_PARADISE_NOW_DEV: '',
  GERCHGROUP: '86f90361-9345-437f-90e1-cbd972f1c778',
  GERCHGROUP_DEV: '23c93663-42c2-43ae-9e78-ddcc6dc9d131',
  CONTENT_AND_CREATORS: 'c39f9a9d-8a07-4dd4-bcac-392a0cf4639a',
  CONTENT_AND_CREATORS_DEV: '7cd94893-f4cf-4311-a8a7-2661e1fef6f6',
  MOERSCHEN: 'c45b2952-7087-4447-a3ab-c35598ae18ce',
  MOERSCHEN_DEV: 'c45b2952-7087-4447-a3ab-c35598ae18ce',
};

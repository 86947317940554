import jwtDecode from 'jwt-decode';

import { TOAST_TYPE } from '@/constants';
import { SEND_CONTACT_FORM_DATA } from '~/graphql/mutations';

export const state = () => ({
  flashMessage: {},
  loading: false,
});

export const mutations = {
  setFlashMessage(state, { type, message }) {
    state.flashMessage = { type, message };
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

export const getters = {
  loading(state) {
    return state.loading;
  },
};

export const actions = {
  handleError(
    { commit, rootState },
    { e, queryName, variables, showError = true },
  ) {
    let errorMessage = this.$i18n.t('error.somethingWentWrong');
    if (!e.status) {
      errorMessage = e.message;
    }
    if (e.graphQLErrors?.length) {
      const {
        message,
        extensions: { status },
      } = e.graphQLErrors[0];
      if (status !== 500) {
        errorMessage = message;
      }
    }
    const context = {
      user: {
        username: `${rootState.auth.user.firstName} ${rootState.auth.user.lastName}`,
        email: rootState.auth.user.email,
      },
      extra: {
        queryName,
        errorName: e.name,
        errorMessage: e.message,
        variables: variables ?? '',
        profile: rootState.profile.profile ?? '',
      },
      tags: {
        email: rootState?.auth?.user?.email ?? '',
        t2lIdentifier: rootState?.auth?.user?.personIdentifier ?? '',
        // matomoId: rootState?.auth?.user?.personIdentifier ?? 'Not logged in',
      },
    };
    console.error(`Error at ${queryName}: ${errorMessage}`);
    if (showError) {
      commit('setFlashMessage', {
        type: TOAST_TYPE.ERROR,
        message: errorMessage,
      });
    }
    const avoidCapture = [
      'The account you tried to log in with doesn’t exist. Please, make sure that you used the correct e-mail and password.',
      'This email address is already registered',
    ];
    if (avoidCapture.includes(errorMessage)) return;
    this.$sentry.captureException(e, context);
  },
  nuxtServerInit({ commit }, { req }) {
    const token = JSONCookies(req.headers.cookie)?.['apollo-token'];
    if (!token) {
      return;
    }
    try {
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        commit('auth/setUserData', decodedToken);
      }
    } catch {
      /* eslint-disable no-console */
      console.warning('Unable to decode a token');
    }
  },
  async sendContactMail({ commit }, formData) {
    commit('setLoading', true);
    const client = this.app.apolloProvider.defaultClient;
    const { name, email, message } = formData;
    try {
      await client.mutate({
        mutation: SEND_CONTACT_FORM_DATA,
        variables: {
          name,
          email,
          message,
          referer: 'tap2link',
          recipient: 'contact@t2l.ink',
        },
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
};

export function JSONCookies(stringCookie = '') {
  return Object.fromEntries(
    stringCookie.split('; ').map((c) => {
      const [key, ...v] = c.split('=');
      return [key, v.join('=')];
    }),
  );
}

export default {
  setCustomerInvoices(state, payments) {
    state.customerInvoices = payments
      .map((item) => {
        return item.invoices[0];
      })
      .sort((a, b) => {
        if (a.period_start < b.period_start) {
          return 1;
        }
        if (a.period_start > b.period_start) {
          return -1;
        }
        return 0;
      });
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setAvailablePlans(state, plans) {
    state.availablePlans = plans;
  },
};

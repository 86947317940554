import gql from 'graphql-tag';

export const POSTS = gql`
  query POSTS($locale: I18NLocaleCode) {
    posts(locale: $locale) {
      data {
        id
        attributes {
          slug
          title
        }
      }
    }
  }
`;

export const POST_BY_SLUG = gql`
  query POST_BY_SLUG($slug: String!, $locale: I18NLocaleCode) {
    posts(filters: { slug: { eq: $slug } }, locale: $locale) {
      data {
        id
        attributes {
          slug
          title
          featuredImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          abstract {
            content
          }
          sections {
            id
            title
            description
            variant
            picture {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            list {
              content
            }
          }
          localizations {
            data {
              attributes {
                slug
                locale
              }
            }
          }
        }
      }
    }
  }
`;

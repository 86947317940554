/* eslint-disable no-console */
import { InMemoryCache } from 'apollo-cache-inmemory';

export default () => {
  const cache = new InMemoryCache();
  return {
    httpEndpoint: process.env.PAYMENT_URL,
    cache,
  };
};

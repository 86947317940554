import scrollToId from '~/utils/scrollToId';
export default {
  computed: {
    rheinFireNavItems() {
      return [
        {
          id: '1',
          title: this.$t('rheinfire.about'),
          click: () => this.$router.push('/'),
        },
        {
          id: '2',
          title: this.$t('global.contact'),
          click: () =>
            window.open(
              'https://www.rheinfire.eu/kontakt/',
              '_blank',
              'noopener',
            ),
        },
        {
          id: '3',
          title: this.$t('global.FAQ'),
          click: () => this.scrollToId('faq'),
        },
      ];
    },
    footerItems() {
      return [
        {
          id: 1,
          title: this.$t('global.home'),
          slug: '/#app',
        },
        {
          id: 2,
          title: this.$t('global.forBusiness'),
          slug: '/business',
        },
        {
          id: 3,
          title: this.$t('global.blog'),
          subItems: [...this.posts],
        },
        {
          id: 4,
          title: this.$t('global.FAQ'),
          slug: '/faq',
        },
        {
          id: 5,
          title: this.$t('global.contact'),
          slug: '/contact',
        },
        {
          id: 6,
          title: this.$t('global.legalInformation'),
          link: 'https://shop.t2l.ink/policies/legal-notice',
          target: '_blank',
        },
        {
          id: 7,
          title: this.$t('global.privacyPolicy'),
          link: 'https://shop.t2l.ink/policies/privacy-policy',
          target: '_blank',
        },
      ];
    },
    navBarClasses() {
      let color = 'white';
      if (this.$route.fullPath === '/rheinfire/homepage') {
        color = '';
      }
      if (!this.isScrollUp && this.isScrollHigher) {
        return `nav__bar--hidden ${color}`;
      }
      if (this.isScrollHigher) {
        return `nav__bar--shadow ${color}`;
      }
      if (this.$route.name === 'index' || this.$route.name === 'business') {
        return 'mt-6';
      }
      if (this.$route.fullPath.includes('/blog')) {
        return 'white';
      }
      return '';
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    scrollToId,
    goToTop(url) {
      process.client && window.scrollTo(0, 0);
      this.$router.push(url);
    },
    toggleMenu() {
      this.drawer = !this.drawer;
    },
    handleResize() {
      if (window.innerWidth > 960) {
        this.drawer = false;
      }
    },
    handleScroll() {
      this.isScrollUp = this.scrollLastPosition > window.scrollY;
      this.isScrollHigher = window.scrollY > 40;
      this.scrollLastPosition = window.scrollY;
    },
  },
};

import Cookie from 'js-cookie';

export default {
  mounted() {
    Cookie.set('multiple-tabs', false);
    localStorage.openPages = Date.now();
    window.addEventListener('storage', this.checkActiveTabs, false);
  },
  beforeDestroy() {
    Cookie.set('multiple-tabs', false);
    window.removeEventListener('storage', this.checkActiveTabs, false);
  },
  methods: {
    checkActiveTabs(event) {
      if (event.key == 'openPages') {
        Cookie.set('multiple-tabs', true);
      }
    },
  },
};

import axios from 'axios';

import {
  ADMIN_ROLE,
  HUBSPOT_INTERGRATION_STATUS,
  HUBSPOT_URL,
} from '~/constants';
import {
  ADD_CARD_TO_PROFILE,
  DISCONNECT_CARD_FROM_PROFILE,
  DISCONNECT_SAVED_PROFILE,
  FIND_ALL_PROFILE_ORGANIZATION,
  FIND_CARD_BY_IDENTIFIER,
  FIND_CARD_BY_QR_CODE,
  FIND_PROFILE_BY,
  GET_IMAGE_BASE64,
  GET_RELATED_ORGANIZATION,
  IS_PROFILE_SAVED,
  REFRESH_SCANNER_QR_CODE,
  SAVE_PROFILE,
  UPDATE_PROFILE_NAME,
  USER_ORGANIZATIONS,
  USER_PROFILES,
} from '~/graphql';

export default {
  async fetchUserProfiles({ commit, dispatch }) {
    const client = this.app.apolloProvider.clients.application;
    if (!this.$apolloHelpers.getToken()) return;
    try {
      const {
        data: { userProfiles },
      } = await client.query({
        query: USER_PROFILES,
        fetchPolicy: 'no-cache',
      });
      if (userProfiles) {
        const profileIds = userProfiles.map((user) => user.id);
        await dispatch('fetchUserOrganizations');
        await dispatch('fetchProfileOrganizations', {
          profileIds,
          singleProfile: false,
        });
      }
      commit('setUserProfiles', userProfiles);
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'USER_PROFILES' },
        { root: true },
      );
    }
  },
  async fetchUserOrganizations({ commit, dispatch }) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { userOrganizations },
      } = await client.query({
        query: USER_ORGANIZATIONS,
        fetchPolicy: 'no-cache',
      });
      commit(
        'setOrganizations',
        userOrganizations.map((item) => ({
          ...item.organization,
          role: item.role,
        })),
      );
      return userOrganizations;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'USER_ORGANIZATIONS' },
        { root: true },
      );
      return null;
    }
  },
  async fetchProfileOrganizations({ commit, dispatch }, props) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { findAllProfileOrganization },
      } = await client.query({
        query: FIND_ALL_PROFILE_ORGANIZATION,
        fetchPolicy: 'no-cache',
        variables: {
          profileIds: props.profileIds,
        },
      });
      if (props.singleProfile) {
        commit('setCurrentProfileOrganizations', findAllProfileOrganization);
        return findAllProfileOrganization?.[0];
      } else {
        commit('setUserProfilesOrganizations', findAllProfileOrganization);
      }
      return findAllProfileOrganization;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'FIND_ALL_PROFILE_ORGANIZATION' },
        { root: true },
      );
      return null;
    }
  },
  // TODO make those two requests one
  async fetchCardByQrCode({ dispatch }, url) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { findCardByQrCode },
      } = await client.query({
        query: FIND_CARD_BY_QR_CODE,
        variables: {
          qrCodeUrl: url,
        },
      });
      if (findCardByQrCode?.identifier_int) {
        this.app.router.push(`/card/${findCardByQrCode.identifier_int}`);
      }
      return findCardByQrCode;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'FIND_CARD_BY_QR_CODE', variables: url },
        { root: true },
      );
      return null;
    }
  },
  async fetchCard({ dispatch }, identifier_int) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { findCardByIdentifier },
      } = await client.query({
        query: FIND_CARD_BY_IDENTIFIER,
        fetchPolicy: 'no-cache',
        variables: {
          identifier_int: parseFloat(identifier_int),
        },
      });
      return findCardByIdentifier;
    } catch (e) {
      const variables = { identifier_int };
      dispatch(
        'handleError',
        {
          e,
          queryName: 'FIND_CARD_BY_IDENTIFIER',
          variables,
          showError: !e.message.includes('Card was not found'),
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchProfile({ commit, dispatch }, options) {
    const {
      organizationUniqueValue,
      profileUniqueValue,
      privateCustomLinks = true,
      scope = true,
      organizationProfile = false,
    } = options;
    const client = this.app.apolloProvider.clients.application;
    if (!profileUniqueValue) return null;
    try {
      const {
        data: { findProfileBy },
      } = await client.query({
        query: FIND_PROFILE_BY,
        fetchPolicy: 'no-cache',
        variables: {
          profileUniqueValue,
          organizationUniqueValue,
          privateCustomLinks,
          scope,
        },
      });
      if (!findProfileBy) return null;
      if (organizationProfile) {
        commit('organization/setOrganizationProfile', findProfileBy, {
          root: true,
        });
        return findProfileBy;
      }
      commit('setProfile', findProfileBy);
      return findProfileBy;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'FIND_PROFILE_BY' },
        { root: true },
      );
      return null;
    }
  },
  async addCardToProfile({ dispatch }, data) {
    const client = this.app.apolloProvider.clients.application;
    const { identifier_int, profileId } = data;
    try {
      const {
        data: { addCardToProfile },
      } = await client.mutate({
        mutation: ADD_CARD_TO_PROFILE,
        variables: {
          identifier_int: parseFloat(identifier_int),
          profileId,
        },
      });
      await dispatch('fetchProfile', {
        profileUniqueValue: this.app.context.route.params.id,
      });
      return !!addCardToProfile?.id;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'ADD_CARD_TO_PROFILE', variables: data },
        { root: true },
      );
    }
  },
  async disconnectProfileCard({ dispatch }, cardId) {
    const client = this.app.apolloProvider.clients.application;
    try {
      await client.mutate({
        mutation: DISCONNECT_CARD_FROM_PROFILE,
        variables: {
          id: cardId,
        },
      });
      await dispatch('fetchProfile', {
        organizationUniqueValue: this.app.context.route.params.organization,
        profileUniqueValue: this.app.context.route.params.id,
      });
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'DISCONNECT_CARD_FROM_PROFILE', variables: cardId },
        { root: true },
      );
    }
  },
  async getImageBase64({ commit, dispatch }, fileName) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { getImageBase64 },
      } = await client.query({
        query: GET_IMAGE_BASE64,
        fetchPolicy: 'no-cache',
        variables: {
          fileName,
        },
      });
      commit('setImageBase64', getImageBase64);
      return getImageBase64;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'GET_IMAGE_BASE64' },
        { root: true },
      );
      return '';
    }
  },
  async updateProfileName({ dispatch }, variables) {
    const client = this.app.apolloProvider.clients.application;
    try {
      await client.mutate({
        mutation: UPDATE_PROFILE_NAME,
        variables,
      });
      dispatch('fetchProfile', {
        profileUniqueValue: variables.profileId,
      });
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'UPDATE_PROFILE_NAME' },
        { root: true },
      );
    }
  },
  async refreshScannerQrCode({ dispatch }, profileId) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { refreshScannerQrCode },
      } = await client.mutate({
        mutation: REFRESH_SCANNER_QR_CODE,
        variables: { profileId },
      });
      return refreshScannerQrCode;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'REFRESH_SCANNER_QR_CODE' },
        { root: true },
      );
      return false;
    }
  },
  async savedProfile({ dispatch }, profileId) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { saveProfile },
      } = await client.mutate({
        mutation: SAVE_PROFILE,
        variables: { profileId },
      });
      return saveProfile;
    } catch (e) {
      dispatch('handleError', { e, queryName: 'SAVE_PROFILE' }, { root: true });
      return false;
    }
  },
  async getCompanyProducts({ dispatch }, organizationId) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { getRelatedOrganization },
      } = await client.query({
        query: GET_RELATED_ORGANIZATION,
        fetchPolicy: 'no-cache',
        variables: { organizationId },
      });
      const profileId = getRelatedOrganization?.relatedOrganization?.id;
      if (profileId) {
        const company = await dispatch(
          'organization/getOrganization',
          { profileId },
          { root: true },
        );
        const id = company?.profile?.id;
        dispatch(
          'organization/findAllProducts',
          { organizationUniqueValue: id },
          { root: true },
        );
      }
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'GET_COMPANY_PRODUCTS' },
        { root: true },
      );
    }
  },
  async sendHubspotEvent({ dispatch, rootState }, data) {
    const { eventType, organizationId, payloadData } = data;
    const { email, lastName } = rootState.auth.user;
    try {
      await axios.post(HUBSPOT_URL.SAVE_HUBSPOT_EVENT, {
        eventType,
        organizationId,
        payload: {
          ...payloadData,
          email,
          contactEmail: email,
          contactSurname: lastName,
        },
      });
      return true;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'SAVE_HUBSPOT_EVENT', variables: data },
        { root: true },
      );
    }
  },
  async isProfileSaved({ dispatch }, profileId) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { isProfileSaved },
      } = await client.query({
        query: IS_PROFILE_SAVED,
        fetchPolicy: 'no-cache',
        variables: { profileId },
      });
      return isProfileSaved;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'IS_PROFILE_SAVED' },
        { root: true },
      );
    }
  },

  async disconnectSavedProfile({ dispatch }, id) {
    const client = this.app.apolloProvider.clients.application;
    try {
      await client.mutate({
        mutation: DISCONNECT_SAVED_PROFILE,
        variables: { profileId: id },
      });
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'DISCONNECT_SAVED_PROFILE' },
        { root: true },
      );
    }
  },

  async confirmHubspotConnection({ dispatch, state }, code) {
    await dispatch('fetchUserOrganizations');
    const connectingOrganization = await state.organizations.find(
      ({ role, hubspot }) =>
        hubspot?.status === HUBSPOT_INTERGRATION_STATUS.CONNECTING &&
        role === ADMIN_ROLE.OWNER,
    );
    if (!connectingOrganization?.id) {
      dispatch(
        'handleError',
        {
          e: {
            message:
              'Hubspot connection failed. (Current user is not related with connecting company)',
          },
          queryName: 'ORGANIZATION_HUBSPOT',
        },
        { root: true },
      );
      return false;
    }
    try {
      await axios.post(`${HUBSPOT_URL.CONFIRM_INTEGRATION}`, {
        organizationId: connectingOrganization.id,
        code,
      });
      return connectingOrganization.profile.id;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'ORGANIZATION_HUBSPOT' },
        { root: true },
      );
      return false;
    }
  },
};

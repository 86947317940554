export default {
  setPosts(state, payload) {
    if (Array.isArray(payload)) {
      state.posts = payload.map((post) => {
        post.slug = `/blog/${post.slug}`;
        return post;
      });
    }
  },
  setPost(state, payload) {
    state.post = payload;
  },
};

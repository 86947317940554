import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faAddressCard as freeFasFaAddressCard,
  faArrowRight as freeFasFaArrowRight,
  faBookmark as freeFasFaBookmark,
  faCaretDown as freeFasFaCaretDown,
  faChevronDown as freeFasFaChevronDown,
  faChevronLeft as freeFasFaChevronLeft,
  faChevronRight as freeFasFaChevronRight,
  faChevronUp as freeFasFaChevronUp,
  faClock as freeFasFaClock,
  faCreditCard as freeFasFaCreditCard,
  faCrown as freeFasFaCrown,
  faEnvelope as freeFasFaEnvelope,
  faEye as freeFasFaEye,
  faEyeSlash as freeFasFaEyeSlash,
  faGlobe as freeFasFaGlobe,
  faGlobeAfrica as freeFasFaGlobeAfrica,
  faGlobeEurope as freeFasFaGlobeEurope,
  faHome as freeFasFaHome,
  faLink as freeFasFaLink,
  faLock as freeFasFaLock,
  faLockOpen as freeFasFaLockOpen,
  faUnlink as freeFasFaUnlink,
  faMapMarkerAlt as freeFasFaMapMarkerAlt,
  faMinus as freeFasFaMinus,
  faMobileAlt as freeFasFaMobileAlt,
  faPhoneAlt as freeFasFaPhoneAlt,
  faPlus as freeFasFaPlus,
  faPlusCircle as freeFasFaPlusCircle,
  faShare as freeFasFaShare,
  faShareAlt as freeFasFaShareAlt,
  faShoppingCart as freeFasFaShoppingCart,
  faStar as freeFasFaStar,
  faTimes as freeFasFaTimes,
  faUser as freeFasFaUser,
  faUserEdit as freeFasFaUserEdit,
  faUserPlus as freeFasFaUserPlus,
  faUserTie as freeFasFaUserTie,
  faUsers as freeFasFaUsers,
  faWallet as freeFasFaWallet,
  faShieldAlt as freeFasFaShieldAlt,
  faCheckCircle as freeFasFaCheckCircle,
  faExclamationCircle as freeFasFaExclamationCircle,
  faEdit as freeFasFaEdit,
  faInfoCircle as freeFasFaInfoCircle,
  faTrash as freeFasFaTrash,
  faTag as freeFasFaTag,
  faDownload as freeFasFaDownload,
  faCheck as freeFasFaCheck,
  faQuestionCircle as freeFasFaQuestionCircle,
  faQrcode as freeFasFaQrcode,
  faMailBulk as freeFasFaMailBulk
} from '@fortawesome/free-solid-svg-icons'

import {
  faApple as freeFabFaApple,
  faDiscord as freeFabFaDiscord,
  faFacebook as freeFabFaFacebook,
  faFacebookF as freeFabFaFacebookF,
  faFacebookMessenger as freeFabFaFacebookMessenger,
  faFacebookSquare as freeFabFaFacebookSquare,
  faGooglePlay as freeFabFaGooglePlay,
  faInstagram as freeFabFaInstagram,
  faLinkedin as freeFabFaLinkedin,
  faLinkedinIn as freeFabFaLinkedinIn,
  faMedapps as freeFabFaMedapps,
  faPinterest as freeFabFaPinterest,
  faSnapchatGhost as freeFabFaSnapchatGhost,
  faSpotify as freeFabFaSpotify,
  faTelegramPlane as freeFabFaTelegramPlane,
  faTiktok as freeFabFaTiktok,
  faTwitch as freeFabFaTwitch,
  faTwitter as freeFabFaTwitter,
  faXing as freeFabFaXing,
  faYoutube as freeFabFaYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaAddressCard,
  freeFasFaArrowRight,
  freeFasFaBookmark,
  freeFasFaCaretDown,
  freeFasFaChevronDown,
  freeFasFaChevronLeft,
  freeFasFaChevronRight,
  freeFasFaChevronUp,
  freeFasFaClock,
  freeFasFaCreditCard,
  freeFasFaCrown,
  freeFasFaEnvelope,
  freeFasFaEye,
  freeFasFaEyeSlash,
  freeFasFaGlobe,
  freeFasFaGlobeAfrica,
  freeFasFaGlobeEurope,
  freeFasFaHome,
  freeFasFaLink,
  freeFasFaLock,
  freeFasFaLockOpen,
  freeFasFaUnlink,
  freeFasFaMapMarkerAlt,
  freeFasFaMinus,
  freeFasFaMobileAlt,
  freeFasFaPhoneAlt,
  freeFasFaPlus,
  freeFasFaPlusCircle,
  freeFasFaShare,
  freeFasFaShareAlt,
  freeFasFaShoppingCart,
  freeFasFaStar,
  freeFasFaTimes,
  freeFasFaUser,
  freeFasFaUserEdit,
  freeFasFaUserPlus,
  freeFasFaUserTie,
  freeFasFaUsers,
  freeFasFaWallet,
  freeFasFaShieldAlt,
  freeFasFaCheckCircle,
  freeFasFaExclamationCircle,
  freeFasFaEdit,
  freeFasFaInfoCircle,
  freeFasFaTrash,
  freeFasFaTag,
  freeFasFaDownload,
  freeFasFaCheck,
  freeFasFaQuestionCircle,
  freeFasFaQrcode,
  freeFasFaMailBulk,
  freeFabFaApple,
  freeFabFaDiscord,
  freeFabFaFacebook,
  freeFabFaFacebookF,
  freeFabFaFacebookMessenger,
  freeFabFaFacebookSquare,
  freeFabFaGooglePlay,
  freeFabFaInstagram,
  freeFabFaLinkedin,
  freeFabFaLinkedinIn,
  freeFabFaMedapps,
  freeFabFaPinterest,
  freeFabFaSnapchatGhost,
  freeFabFaSpotify,
  freeFabFaTelegramPlane,
  freeFabFaTiktok,
  freeFabFaTwitch,
  freeFabFaTwitter,
  freeFabFaXing,
  freeFabFaYoutube
)

config.autoAddCss = false

Vue.component('faIcon', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)

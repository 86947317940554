export default {
  'basic-black': '#051822',
  white: '#fff',
  black: '#000',
  primary: '#2562FF',
  divider: '#d2d2d2',
  'dark-grey': '#747690',
  'light-grey': '#F8F8FC',
  'light-grey-border': '#B7B8BB',
  'light-black': '#A5A7B8',
  orange: '#DC8369',
  // application colors
  'button-gray': '#EEF2FB',
  'button-hover-gray': '#D1D1E1',
  'custom-link': '#EFEFF8',
  'custom-link-active': '#DFDFF4',
  'custom-link-hover': '#E9E9F5',
  'dark-background': `#1C1E2C`,
  'error-red': '#FA005A',
  'error-red-light': '#fed6e4',
  'error-red-light-active': '#fd99bd',
  'error-red-light-hover': '#feccde',
  'heartbeat-red': '#960000',
  'hover-gray': '#E7E7F2',
  'input-border-gray': '#EBEBF2',
  'light-2': '#F4F4FB', // hover-gray-2
  'light-hover': '#D6E1FF',
  'light-main': '#ECF1FF',
  'primary-active': '#113AA1', // dark blue
  'primary-dark': '#051822', // dark blue
  'primary-hover': '#0F44CD', // dark blue
  'text-dark': '#3B3D52',
  'text-gray': '#A5A7B8', // gray
  'price-text-gray': '#666666',
  'price-text-lightgray': '#999999',
  light: '#F8F8FC', // light-gray
  overlay: '#212121',
  warning: '#EBFF00',
  'green-confirmation': '#74D13A',
  'confirmation-bg': '#F2FFF4',
  'confirmation-bg-hover': '#e8fbeb',
  'confirmation-bg-active': '#defde3',
  claret: '#530E0E',
  cream: '#CDBA8A',
  'dark-blue': '#051822',
  silver: '#8E8E8C',
  'rheinfire-gray': '#BABABA',
  'light-red': '#FFEBF2',
  'loading-bg': '#c4c4c4',
  'neutral-medium': '#E3E3F0',
  'neutral-white': '#F8F8FC',
  green: '#219653',
};

import removeDuplicatesfromArrayOfObjects from '~/utils/removeDuplicatesfromArrayOfObjects';

export default {
  setUserProfiles(state, profiles) {
    state.userProfiles = profiles;
  },
  setProfile(state, profile) {
    if (profile?.customlinks?.length) {
      profile.customlinks = removeDuplicatesfromArrayOfObjects(
        profile.customlinks,
      );
    }
    state.profile = profile;
  },
  setOrganizations(state, organizations) {
    state.organizations = Array.isArray(organizations) ? organizations : [];
  },
  setCurrentProfileOrganizations(state, organizations) {
    state.currentProfileOrganizations = Array.isArray(organizations)
      ? organizations
      : [];
    state.staffSettings = organizations[0]?.organization?.settings ?? {};
  },
  setUserProfilesOrganizations(state, organizations) {
    state.userProfilesOrganizations = Array.isArray(organizations)
      ? organizations
      : [];
  },
  setImageBase64(state, base64) {
    state.imageBase64 = base64;
  },
  setQrCodeDialog(state, isOpen) {
    state.qrCodeDialog = isOpen;
  },
};

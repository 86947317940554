export default () => ({
  company: {},
  members: [],
  membershipTypes: [],
  numberOfMembers: 0,
  organization: {},
  organizationProfile: {},
  products: [],
  loading: false,
});

import Cookie from 'js-cookie';

import { CUSTOM_URL } from '~/constants';
import {
  CREATE_USER,
  RECOVERY_PASSWORD,
  RECOVERY_PASSWORD_REQUEST,
  SIGN_IN_USER,
} from '~/graphql';

export default {
  updateToken(_, token) {
    this.$apolloHelpers.onLogin(token, undefined, {
      expires: undefined,
      path: '/',
    });
  },
  async signInUser({ commit, dispatch }, variables) {
    const client = this.app.apolloProvider.clients.application;
    commit('setLoading', true);
    try {
      const {
        data: { signInUser },
      } = await client.query({
        query: SIGN_IN_USER,
        fetchPolicy: 'network-only',
        variables,
      });
      if (signInUser?.errors?.length) {
        throw Error(signInUser.errors[0]);
      } else {
        dispatch('updateToken', signInUser?.token);
        commit('setUser', signInUser);
        commit('setLoading', false);
        Cookie.set('only-after-login', true);
        const productUrl = Cookie.get(CUSTOM_URL);
        if (productUrl) {
          Cookie.remove(CUSTOM_URL);
          window.location.href = productUrl;
        } else {
          window.location.href = `${process.env.baseUrl}/app/dashboard`;
        }
      }
    } catch (e) {
      dispatch('handleError', { e, queryName: 'SIGN_IN_USER' }, { root: true });
    } finally {
      commit('setLoading', false);
    }
  },
  async createUser({ commit, dispatch }, variables) {
    const client = this.app.apolloProvider.clients.application;
    commit('setLoading', true);
    try {
      const {
        data: { createUser },
      } = await client.mutate({
        mutation: CREATE_USER,
        variables,
      });
      if (createUser?.errors?.length) {
        throw Error(createUser.errors[0]);
      } else {
        dispatch('updateToken', createUser.token);
        Cookie.set('only-after-login', true);
        const productUrl = Cookie.get(CUSTOM_URL);
        if (productUrl) {
          Cookie.remove(CUSTOM_URL);
          window.location.href = productUrl;
        } else {
          window.location.href = `${process.env.baseUrl}/app/dashboard`;
        }
      }
    } catch (e) {
      dispatch('handleError', { e, queryName: 'CREATE_USER' }, { root: true });
    } finally {
      commit('setLoading', false);
    }
  },
  async sendPasswordRecoveryRequest({ dispatch }, email) {
    const client = this.app.apolloProvider.clients.auth;
    try {
      const {
        data: { recoveryPasswordRequest },
      } = await client.mutate({
        mutation: RECOVERY_PASSWORD_REQUEST,
        variables: {
          email,
          fromEmail: 'contact@t2l.ink',
          fromName: 'tap2link',
        },
      });
      if (recoveryPasswordRequest?.errors?.length) {
        throw Error(recoveryPasswordRequest.errors[0]);
      } else {
        return true;
      }
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'RECOVERY_PASSWORD_REQUEST' },
        { root: true },
      );
      return false;
    }
  },
  async resetPassword({ dispatch }, data) {
    const client = this.app.apolloProvider.clients.auth;
    const { token, password } = data;
    try {
      const {
        data: { recoveryPassword },
      } = await client.mutate({
        mutation: RECOVERY_PASSWORD,
        variables: {
          token,
          password,
        },
      });
      if (recoveryPassword?.errors?.length) {
        throw new Error(recoveryPassword.errors[0]);
      } else {
        this.$router.push({
          path: '/login',
          query: { ...this.$router.history.current.query },
        });
      }
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'RECOVERY_PASSWORD' },
        { root: true },
      );
    }
  },
};

export default {
  computed: {
    isLoggedIn() {
      return !!this.$apolloHelpers.getToken();
    },
  },
  methods: {
    redirect(custom) {
      if (this.isLoggedIn) {
        return (window.location.href = `${process.env.baseUrl}/app/dashboard`);
      }
      if (typeof custom === 'string') {
        this.$router.push(`/welcome${custom}`);
      } else {
        this.$router.push(`/welcome`);
      }
    },
  },
};

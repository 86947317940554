export default {
  posts(state) {
    return state.posts;
  },
  post(state) {
    return state.post;
  },
  abstract(state) {
    return state.post?.abstract || [];
  },
  localizations(state) {
    return state.post?.localizations;
  },
};

import gql from 'graphql-tag';

export const OWNER_RECEIVED_PAYMENTS_BY_SUBSCRIBER_ID = gql`
  query ownerReceivedPaymentsBySubscriberId($subscriberId: String!) {
    ownerReceivedPaymentsBySubscriberId(subscriberId: $subscriberId) {
      customer
      customerFrom
      product
      price
      invoices {
        amount_paid
        created
        currency
        customer_email
        customer_name
        description
        id
        invoice_pdf
        paid
        period_end
        period_start
        status
        total
      }
    }
  }
`;

export const AVAILABLE_PLANS = gql`
  query availablePlans {
    availablePlans {
      active
      aggregate_usage
      amount
      amount_decimal
      billing_scheme
      created
      currency
      id
      interval
      interval_count
      livemode
      nickname
      object
      product
      productName
      tiers_mode
      transform_usage
      trial_period_days
      usage_type
    }
  }
`;

import isEmpty from 'lodash-es/isEmpty';

import { POST_BY_SLUG, POSTS } from '~/graphql/blog';

export default {
  async fetchPosts({ commit, dispatch }) {
    const client = this.app.apolloProvider.clients.cms;
    try {
      const {
        data: { posts },
      } = await client.query({
        query: POSTS,
        fetchPolicy: 'no-cache',
        variables: {
          locale: this.app.i18n.getLocaleCookie(),
        },
      });
      commit(
        'setPosts',
        posts.data.map((post) => ({
          id: post.id,
          ...post.attributes,
        })),
      );
    } catch (e) {
      dispatch('handleError', { e, queryName: 'POSTS' }, { root: true });
    }
  },
  async fetchPostBySlug({ commit, dispatch }, slug) {
    const client = this.app.apolloProvider.clients.cms;
    try {
      const { data } = await client.query({
        query: POST_BY_SLUG,
        fetchPolicy: 'no-cache',
        variables: {
          slug,
          locale: this.app.i18n.getLocaleCookie(),
        },
      });
      const post = data.posts.data?.[0];
      if (isEmpty(post)) return;
      const sections = post.attributes.sections.map((section) => {
        return {
          ...section,
          picture: {
            ...section?.picture?.data?.attributes,
          },
        };
      });
      let postData = {
        id: post.id,
        ...post.attributes,
        featuredImage: {
          ...post.attributes?.featuredImage?.data?.attributes,
        },
        sections,
        abstract: post.attributes?.abstract?.map(({ content }) => content),
        localizations: [
          ...post.attributes?.localizations?.data?.map(
            ({ attributes }) => attributes,
          ),
        ],
      };
      commit('setPost', postData);
    } catch (e) {
      dispatch('handleError', { e, queryName: 'POST_BY_SLUG' }, { root: true });
    }
  },
};

import { HUBSPOT_EVENT, PROFILE_TYPE } from '~/constants';
import {
  CONFIRM_INVITATION,
  FIND_ALL_MEMBERS,
  FIND_ALL_MEMBERSHIP_TYPE,
  FIND_ALL_PRODUCTS,
  GET_MEMBERS_COUNT,
  GET_ORGANIZATION,
  UPDATE_MEMBERSHIP,
} from '~/graphql';

export default {
  async findAllMembers({ commit, dispatch }, profileId) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { findAllMembers },
      } = await client.query({
        query: FIND_ALL_MEMBERS,
        fetchPolicy: 'no-cache',
        variables: {
          profileId,
        },
      });
      if (findAllMembers?.errors?.length) {
        throw Error(findAllMembers.errors[0]);
      } else {
        commit('setMembers', findAllMembers);
      }
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'FIND_ALL_MEMBERS' },
        { root: true },
      );
    }
  },
  async findAllMembershipTypes({ commit, dispatch }, profileId) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { findAllMembershipType },
      } = await client.query({
        query: FIND_ALL_MEMBERSHIP_TYPE,
        variables: {
          profileId,
        },
      });
      if (findAllMembershipType?.errors?.length) {
        throw Error(findAllMembershipType.errors[0]);
      } else {
        commit('setMembershipTypes', findAllMembershipType);
      }
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'FIND_ALL_MEMBERSHIP_TYPE' },
        { root: true },
      );
    }
  },
  async getNumberOfMembers({ commit, dispatch }, uniqueValue) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const {
        data: { getMembersCount },
      } = await client.mutate({
        mutation: GET_MEMBERS_COUNT,
        variables: {
          uniqueValue,
        },
      });
      if (getMembersCount?.errors?.length) {
        throw Error(getMembersCount.errors[0]);
      } else {
        commit('setNumberOfMembers', getMembersCount);
      }
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'GET_MEMBERS_COUNT' },
        { root: true },
      );
    }
  },
  async updateMembership(
    { rootState, dispatch },
    updateProfileOrganizationInput,
  ) {
    const client = this.app.apolloProvider.clients.application;
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_MEMBERSHIP,
        variables: {
          updateProfileOrganizationInput,
        },
      });
      let clubId;
      const { profile } =
        rootState.profile.currentProfileOrganizations[0]?.organization;
      if (profile?.profileType === PROFILE_TYPE.CLUB) {
        clubId = profile?.id;
      }
      if (data && clubId) {
        dispatch('findAllMembers', clubId);
        dispatch(
          'profile/fetchProfileOrganizations',
          {
            profileIds: [rootState.profile.profile.id],
            singleProfile: true,
          },
          { root: true },
        );
      }
      dispatch('profile/fetchUserProfiles', null, { root: true });
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'UPDATE_MEMBERSHIP' },
        { root: true },
      );
    }
  },
  async findAllProducts({ commit, dispatch }, data) {
    const client = this.app.apolloProvider.clients.application;
    const { organizationUniqueValue, checkRelatedOrganization = false } = data;
    try {
      const {
        data: { findAllProducts },
      } = await client.query({
        query: FIND_ALL_PRODUCTS,
        fetchPolicy: 'no-cache',
        variables: {
          organizationUniqueValue,
          checkRelatedOrganization,
        },
      });
      if (findAllProducts?.errors?.length) {
        throw Error(findAllProducts.errors[0]);
      }
      commit('setProducts', findAllProducts);
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'FIND_ALL_PRODUCTS', variables: data },
        { root: true },
      );
    }
  },
  async getOrganization({ commit, dispatch }, data) {
    const client = this.app.apolloProvider.clients.application;
    const { profileId } = data;
    try {
      const {
        data: { getOrganization },
      } = await client.query({
        query: GET_ORGANIZATION,
        fetchPolicy: 'no-cache',
        variables: {
          profileId,
        },
      });
      if (getOrganization?.errors?.length) {
        throw Error(getOrganization.errors[0]);
      }
      commit('setCompany', getOrganization);
      return getOrganization;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'GET_ORGANIZATION' },
        { root: true },
      );
    }
  },
  async confirmInvitation({ commit, dispatch }, data) {
    const { invitationId, organizationId } = data;
    const client = this.app.apolloProvider.clients.application;
    commit('setLoading', true);
    try {
      const {
        data: { confirmInvitation },
      } = await client.mutate({
        mutation: CONFIRM_INVITATION,
        variables: {
          invitationId,
        },
      });
      if (confirmInvitation?.errors?.length) {
        throw Error(confirmInvitation.errors[0]);
      }
      dispatch('profile/fetchUserProfiles', null, { root: true });
      dispatch(
        'profile/sendHubspotEvent',
        {
          eventName: HUBSPOT_EVENT.EVENT_SIGNUP,
          organizationId,
          payload: {},
        },
        { root: true },
      );
      return confirmInvitation;
    } catch (e) {
      dispatch(
        'handleError',
        { e, queryName: 'CONFIRM_INVITATION' },
        { root: true },
      );
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
};

export const COMPANY = {
  name: 'Tap2Link GmbH',
  city: 'Düsseldorf',
  street: 'Breite Str. 27',
  country: 'Germany',
  postalCode: '40213',
  phoneNumber: '0156778 387064',
  email: ' contact@t2l.ink',
  website: 'https://t2l.ink/',
  registryCourt: 'Amtsgericht Düsseldorf',
  registerNumber: 'HRB 95550',
  seatOfTheCompany: 'Düsseldorf',
  taxId: 'DE 332848146',
  director: 'Christian Wenzel',
};

import removeDuplicatesfromArrayOfObjects from '~/utils/removeDuplicatesfromArrayOfObjects';

export default {
  setMembershipTypes(state, payload) {
    state.membershipTypes = payload;
  },
  setMembers(state, payload) {
    state.members = payload;
  },
  setNumberOfMembers(state, num) {
    state.numberOfMembers = num;
  },
  setOrganizationProfile(state, profile) {
    if (profile?.customlinks?.length) {
      profile.customlinks = removeDuplicatesfromArrayOfObjects(
        profile.customlinks,
      );
    }
    state.organizationProfile = profile;
  },
  setProducts(state, products) {
    state.products = products;
  },
  setCompany(state, company) {
    state.company = company;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
};

function redirectToWebflow({ req, route }) {
  if (!req) return;
  console.warn(`${process.env.webflowUrl}${route.fullPath}`);
  if (req?.headers?.host && req.headers.host.startsWith('www.')) return;
  const routesName = ['index', 'business', 'blog-slug', 'faq', 'contact'];
  console.warn(routesName.includes(route.name));
  if (routesName.includes(route.name)) {
    // return redirect(301, `${process.env.webflowUrl}${route.fullPath}`);
  }
}

export default redirectToWebflow;

import gql from 'graphql-tag';

export const SEND_CONTACT_FORM_DATA = gql`
  mutation sendContactFormData(
    $name: String!
    $email: String!
    $message: String!
    $referer: String!
    $recipient: String
    $phone: String
    $company: String
    $role: String
  ) {
    sendContactFormData(
      name: $name
      email: $email
      message: $message
      referer: $referer
      recipient: $recipient
      phone: $phone
      company: $company
      role: $role
    ) {
      errors
    }
  }
`;

export const SIGN_IN_USER = gql`
  query signInUser($username: String!, $password: String!) {
    signInUser(username: $username, password: $password) {
      errors
      user {
        firstName
        lastName
        email
      }
      roles
      token
      startUrl
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $language: String!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      language: $language
    ) {
      errors
      user {
        firstName
        lastName
        email
      }
      roles
      token
      startUrl
    }
  }
`;

export const RECOVERY_PASSWORD_REQUEST = gql`
  mutation recoveryPasswordRequest(
    $email: String!
    $fromEmail: String!
    $fromName: String!
  ) {
    recoveryPasswordRequest(
      email: $email
      fromEmail: $fromEmail
      fromName: $fromName
    ) {
      errors
    }
  }
`;

export const RECOVERY_PASSWORD = gql`
  mutation recoveryPassword($token: String!, $password: String!) {
    recoveryPassword(token: $token, password: $password) {
      errors
      user {
        firstName
        lastName
        email
      }
      roles
      token
      startUrl
    }
  }
`;

export const UPDATE_MEMBERSHIP = gql`
  mutation updateMembership(
    $updateProfileOrganizationInput: UpdateProfileOrganizationInput!
  ) {
    updateMembership(
      updateProfileOrganizationInput: $updateProfileOrganizationInput
    ) {
      membershipStatus
      membershipExpireAt
      id
      createdAt
    }
  }
`;

export const UPDATE_PROFILE_NAME = gql`
  mutation updateProfileName($name: String!, $profileId: String!) {
    updateProfileName(name: $name, profileId: $profileId)
  }
`;

export const ADD_CARD_TO_PROFILE = gql`
  mutation ($identifier_int: Float!, $profileId: String!) {
    addCardToProfile(identifier_int: $identifier_int, profileId: $profileId) {
      id
    }
  }
`;

export const DISCONNECT_CARD_FROM_PROFILE = gql`
  mutation ($id: String!) {
    disconnectCardFromProfile(id: $id) {
      id
    }
  }
`;

export const REFRESH_SCANNER_QR_CODE = gql`
  mutation REFRESH_SCANNER_QR_CODE($profileId: String!) {
    refreshScannerQrCode(profileId: $profileId)
  }
`;

export const SAVE_PROFILE = gql`
  mutation saveProfile($profileId: String!) {
    saveProfile(profileId: $profileId)
  }
`;

export const CONFIRM_INVITATION = gql`
  mutation confirmInvitation($invitationId: String!) {
    confirmInvitation(invitationId: $invitationId)
  }
`;

export const DISCONNECT_SAVED_PROFILE = gql`
  mutation disconnectSavedProfile($profileId: String!) {
    disconnectSavedProfile(profileId: $profileId)
  }
`;

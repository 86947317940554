export default {
  loading(state) {
    return state.loading;
  },
  userData(state) {
    return state.user;
  },
  userEmail(state) {
    return state.user?.email;
  },
};

import { mapGetters } from 'vuex';

import { PROFILE_STATUS, PROFILE_TYPE } from '~/constants';

export default {
  computed: {
    ...mapGetters('profile', [
      'profile',
      'isPersonal',
      'isProfileOwner',
      'isProfileFromMyClub',
      'isAdmin',
      'currentOrganizationProfile',
    ]),
  },
  methods: {
    goToDashboard() {
      let profileType = 'club';
      let dashboard = 'members';
      if (
        this.currentOrganizationProfile?.profileType === PROFILE_TYPE.COMPANY ||
        this.profile.profileType === PROFILE_TYPE.COMPANY
      ) {
        profileType = 'company';
        dashboard = 'staff';
      }
      if (!this.isAdmin) {
        window.location.href = `${process.env.baseUrl}/app/dashboard`;
        return;
      }
      const organizationProfileStatus =
        this.profile.profileType === PROFILE_TYPE.PERSONAL
          ? this.currentOrganizationProfile.profileStatus
          : this.profile.profileStastus;
      if (organizationProfileStatus === PROFILE_STATUS.PUBLIC) {
        window.location.href = `${process.env.baseUrl}/app/${this.$route.params.organization}/${dashboard}`;
      } else {
        window.location.href = `${process.env.baseUrl}/app/${profileType}/${this.$route.params.organization}/${dashboard}`;
      }
    },
  },
};

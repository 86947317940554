import {
  ADMIN_ROLE,
  CUSTOM_LINK_TYPE,
  CUSTOM_THEME_ID,
  PROFILE_TYPE,
} from '~/constants';
import { getStorageUrl } from '~/helpers';

export default {
  backgroundPhotoUrl(state, getters) {
    const organizationProfile =
      getters.currentProfileOrganization?.organization.profile;
    if (organizationProfile) {
      return organizationProfile?.backgroundPhotoName
        ? getStorageUrl(organizationProfile.backgroundPhotoName)
        : getStorageUrl(organizationProfile?.photoName);
    }
    if (state.profile?.backgroundPhotoName) {
      return getStorageUrl(state.profile?.backgroundPhotoName);
    }
    if (state.profile?.photoName) {
      return getStorageUrl(state.profile?.photoName);
    }
  },
  contactLinks(state) {
    if (!state.profile?.customLinks) {
      return [];
    }
    return state.profile.customLinks.filter(
      (link) => link.groupType === CUSTOM_LINK_TYPE.CONTACT && link.link,
    );
  },
  companyId(state) {
    const organization = state.organizations.find(
      (org) => org?.profile?.id === state.profile?.id,
    );
    return organization?.relatedOrganization?.id;
  },
  currentCompanyProfile(_, getters) {
    return getters.currentProfileOrganization?.organization?.profile ?? {};
  },
  currentOrganizationProfile(_, getters) {
    return getters.currentProfileOrganization?.organization.profile || null;
  },
  currentProfileMembership(state) {
    return state.currentProfileOrganizations?.[0]?.membershipType || null;
  },
  currentProfileOrganization(state) {
    return state.currentProfileOrganizations?.[0] || null;
  },
  customLinks(state) {
    if (!state.profile?.customLinks) {
      return [];
    }
    return state.profile.customLinks.filter(
      (link) => link.groupType === CUSTOM_LINK_TYPE.CUSTOM_LINK,
    );
  },
  customThemeId(_, getters) {
    const companyId = getters.currentOrganizationProfile?.id;
    const profileId = getters.profile?.id;
    switch (companyId || profileId) {
      case CUSTOM_THEME_ID.MOERSCHEN:
      case CUSTOM_THEME_ID.MOERSCHEN_DEV:
        return 'theme-moerschen';
      case CUSTOM_THEME_ID.GERCHGROUP:
      case CUSTOM_THEME_ID.GERCHGROUP_DEV:
        return 'theme-gerch';
      case CUSTOM_THEME_ID.CONTENT_AND_CREATORS:
      case CUSTOM_THEME_ID.CONTENT_AND_CREATORS_DEV:
        return 'theme-cc';
      case CUSTOM_THEME_ID.T2LOVE:
        return 'theme-t2love';
      case CUSTOM_THEME_ID.THE_PARADISE_NOW:
        return 'theme-tpn';
      default:
        return '';
    }
  },
  getBase64(state) {
    return state.imageBase64.replace('data:image/png;base64,', '');
  },
  getOrganization(state) {
    return function (profileId) {
      return state.organizations.find((organization) => {
        return organization.profile.id === profileId;
      });
    };
  },
  getProfileOrganization(_, getters) {
    return function (profileId) {
      return getters.userProfilesOrganizations.find((organization) => {
        return organization.member.id === profileId;
      });
    };
  },
  highlighted(state) {
    if (!state.profile?.customLinks) {
      return [];
    }
    return state.profile.customLinks
      .filter((link) => link.groupType === CUSTOM_LINK_TYPE.TILE)
      .reverse();
  },
  isAdmin(state, getters) {
    if (state.organizations.length) {
      const organizationProfile =
        getters.currentProfileOrganization?.organization.profile;
      const organizationProfileId =
        state.profile.profileType === PROFILE_TYPE.PERSONAL
          ? organizationProfile?.id
          : state.profile.id;
      const organization = getters.userOrganizations.find(
        (organization) => organization.profile.id === organizationProfileId,
      );
      return (
        organization?.role === ADMIN_ROLE.ADMIN ||
        organization?.role === ADMIN_ROLE.OWNER
      );
    }
    return false;
  },
  isClub(state) {
    return state.profile?.profileType === PROFILE_TYPE.CLUB;
  },
  isCompany(state) {
    return state.profile?.profileType === PROFILE_TYPE.COMPANY;
  },
  isCompanyOwner(state) {
    const id =
      state.currentProfileOrganizations?.[0]?.organization?.profile?.id;
    return !!state.organizations.filter((org) => {
      if (org?.profile?.profileType === PROFILE_TYPE.COMPANY) {
        return (
          org?.profile?.id === id &&
          [ADMIN_ROLE.ADMIN, ADMIN_ROLE.OWNER].includes(org?.role)
        );
      }
    }).length;
  },
  isPersonal(state) {
    return state.profile?.profileType === PROFILE_TYPE.PERSONAL;
  },
  isProduct(state) {
    return state.profile?.profileType === PROFILE_TYPE.PRODUCT;
  },
  isProfileFromMyClub(_, getters) {
    return !!getters.userOrganizations.find((organization) => {
      return (
        organization.id === getters.currentProfileOrganization?.organization.id
      );
    });
  },
  isProfileOwner(state) {
    if (state.userProfiles?.length && state.profile) {
      return !!state.userProfiles.find(
        (profile) => profile.id === state.profile.id,
      );
    }
    return false;
  },
  isStaffProfile(_, getters) {
    return (
      getters.currentOrganizationProfile?.id &&
      getters.currentOrganizationProfile?.profileType === PROFILE_TYPE.COMPANY
    );
  },
  personalProfiles(state) {
    return state.userProfiles.filter(
      (profile) => profile?.profileType === PROFILE_TYPE.PERSONAL,
    );
  },
  photoUrl(state) {
    if (state.profile?.photoName) {
      return getStorageUrl(state.profile?.photoName);
    }
  },
  privateContactLinks(state) {
    if (state.userProfiles?.length && state.profile) {
      const customLinks =
        state.userProfiles.find((profile) => profile.id === state.profile.id)
          ?.customLinks ?? [];
      return customLinks.filter(
        (link) => link.groupType === CUSTOM_LINK_TYPE.CONTACT && link.link,
      );
    }
    return [];
  },
  profile(state) {
    return state.profile;
  },
  profileCard(state) {
    return state.profile?.cards[0] || null;
  },
  profileHaveContactLinks(_, getters) {
    return !!getters.contactLinks.length;
  },
  profileId(state) {
    return state.userProfiles.filter(
      (profile) => profile.profileType === PROFILE_TYPE.CLUB,
    )?.[0]?.id;
  },
  qrCodeDialog(state) {
    return state.qrCodeDialog;
  },
  staffSettings(state) {
    if (!Object.keys(state.staffSettings).length) return null;
    const components = state.staffSettings.components;
    const componentInfo = {
      ABOUT: {
        id: 1,
        component: 'About',
        settingsName: 'aboutSection',
        props: null,
      },
      PERSONAL_CONTACT_DETAILS: {
        id: 2,
        component: 'ContactDetails',
        settingsName: 'personalContactDetails',
        props: null,
      },
      COMPANY_CONTACT_DETAILS: {
        id: 3,
        component: 'ContactDetails',
        settingsName: 'showCompanyContacts',
        props: {
          'organization-links': true,
        },
      },
      PERSONAL_LINKS: {
        id: 4,
        component: 'CustomLinks',
        settingsName: 'personalLinks',
        props: null,
      },
      COMPANY_LINKS: {
        id: 5,
        component: 'CustomLinks',
        settingsName: 'showCompanyLinks',
        props: {
          company: true,
        },
      },
    };
    const componentsInfo = components?.map((item, index) => {
      if (typeof item !== 'string') {
        return {
          id: index,
          component: null,
          props: null,
        };
      }
      return componentInfo[item];
    });
    return { ...state.staffSettings, components: componentsInfo };
  },
  userOrganizations(state) {
    return state.organizations;
  },
  userProfilesOrganizations(state) {
    return state.userProfilesOrganizations;
  },
  userProfiles(state) {
    return state.userProfiles;
  },
  isHubSpot(state) {
    return !!state.currentProfileOrganizations?.[0]?.organization?.hubspot?.id;
  },
};

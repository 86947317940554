import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3c384fbc = () => interopDefault(import('../pages/business.vue' /* webpackChunkName: "pages/business" */))
const _ec866658 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _5ac9d5a1 = () => interopDefault(import('../pages/eula.vue' /* webpackChunkName: "pages/eula" */))
const _fd83926c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0da8d468 = () => interopDefault(import('../pages/legal-information.vue' /* webpackChunkName: "pages/legal-information" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3e2e4f88 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _35c6e6d0 = () => interopDefault(import('../pages/profile-not-found.vue' /* webpackChunkName: "pages/profile-not-found" */))
const _02f0d5c2 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _f428848a = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _e2751fee = () => interopDefault(import('../pages/tutorials.vue' /* webpackChunkName: "pages/tutorials" */))
const _5b1ecce6 = () => interopDefault(import('../pages/vcf.vue' /* webpackChunkName: "pages/vcf" */))
const _f1d859d4 = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _f4fd5e48 = () => interopDefault(import('../pages/hubspot/confirm.vue' /* webpackChunkName: "pages/hubspot/confirm" */))
const _4748cd3b = () => interopDefault(import('../pages/password-recovery/request.vue' /* webpackChunkName: "pages/password-recovery/request" */))
const _067d5b0f = () => interopDefault(import('../pages/profile/default.vue' /* webpackChunkName: "pages/profile/default" */))
const _04d55b4f = () => interopDefault(import('../pages/rheinfire/homepage.vue' /* webpackChunkName: "pages/rheinfire/homepage" */))
const _5b18714e = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _2e8dc692 = () => interopDefault(import('../pages/card/_id.vue' /* webpackChunkName: "pages/card/_id" */))
const _27c722b8 = () => interopDefault(import('../pages/club/_organization/index.vue' /* webpackChunkName: "pages/club/_organization/index" */))
const _e325ff92 = () => interopDefault(import('../pages/company/_organization/index.vue' /* webpackChunkName: "pages/company/_organization/index" */))
const _0bef0f04 = () => interopDefault(import('../pages/password-recovery/_token.vue' /* webpackChunkName: "pages/password-recovery/_token" */))
const _6b245d08 = () => interopDefault(import('../pages/profile/_id.vue' /* webpackChunkName: "pages/profile/_id" */))
const _8129d070 = () => interopDefault(import('../pages/qr/_id.vue' /* webpackChunkName: "pages/qr/_id" */))
const _cb561b82 = () => interopDefault(import('../pages/company/_organization/product/_id.vue' /* webpackChunkName: "pages/company/_organization/product/_id" */))
const _aca319c0 = () => interopDefault(import('../pages/club/_organization/_id.vue' /* webpackChunkName: "pages/club/_organization/_id" */))
const _b248b742 = () => interopDefault(import('../pages/company/_organization/_id.vue' /* webpackChunkName: "pages/company/_organization/_id" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _68dacbc9 = () => interopDefault(import('../pages/_organization/index.vue' /* webpackChunkName: "pages/_organization/index" */))
const _b516c5f6 = () => interopDefault(import('../pages/_company/event.vue' /* webpackChunkName: "pages/_company/event" */))
const _cf292684 = () => interopDefault(import('../pages/_company/invitation.vue' /* webpackChunkName: "pages/_company/invitation" */))
const _29676f5e = () => interopDefault(import('../pages/_organization/product/_id.vue' /* webpackChunkName: "pages/_organization/product/_id" */))
const _61e9a71e = () => interopDefault(import('../pages/_organization/_id.vue' /* webpackChunkName: "pages/_organization/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/business",
    component: _3c384fbc,
    pathToRegexpOptions: {"strict":true},
    name: "business"
  }, {
    path: "/contact",
    component: _ec866658,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/eula",
    component: _5ac9d5a1,
    pathToRegexpOptions: {"strict":true},
    name: "eula"
  }, {
    path: "/faq",
    component: _fd83926c,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/legal-information",
    component: _0da8d468,
    pathToRegexpOptions: {"strict":true},
    name: "legal-information"
  }, {
    path: "/login",
    component: _b4d4bac6,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/privacy",
    component: _3e2e4f88,
    pathToRegexpOptions: {"strict":true},
    name: "privacy"
  }, {
    path: "/profile-not-found",
    component: _35c6e6d0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-not-found"
  }, {
    path: "/register",
    component: _02f0d5c2,
    pathToRegexpOptions: {"strict":true},
    name: "register"
  }, {
    path: "/terms",
    component: _f428848a,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/tutorials",
    component: _e2751fee,
    pathToRegexpOptions: {"strict":true},
    name: "tutorials"
  }, {
    path: "/vcf",
    component: _5b1ecce6,
    pathToRegexpOptions: {"strict":true},
    name: "vcf"
  }, {
    path: "/welcome",
    component: _f1d859d4,
    pathToRegexpOptions: {"strict":true},
    name: "welcome"
  }, {
    path: "/hubspot/confirm",
    component: _f4fd5e48,
    pathToRegexpOptions: {"strict":true},
    name: "hubspot-confirm"
  }, {
    path: "/password-recovery/request",
    component: _4748cd3b,
    pathToRegexpOptions: {"strict":true},
    name: "password-recovery-request"
  }, {
    path: "/profile/default",
    component: _067d5b0f,
    pathToRegexpOptions: {"strict":true},
    name: "profile-default"
  }, {
    path: "/rheinfire/homepage",
    component: _04d55b4f,
    pathToRegexpOptions: {"strict":true},
    name: "rheinfire-homepage"
  }, {
    path: "/blog/:slug?",
    component: _5b18714e,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug"
  }, {
    path: "/card/:id?",
    component: _2e8dc692,
    pathToRegexpOptions: {"strict":true},
    name: "card-id"
  }, {
    path: "/club/:organization",
    component: _27c722b8,
    pathToRegexpOptions: {"strict":true},
    name: "club-organization"
  }, {
    path: "/company/:organization",
    component: _e325ff92,
    pathToRegexpOptions: {"strict":true},
    name: "company-organization"
  }, {
    path: "/password-recovery/:token?",
    component: _0bef0f04,
    pathToRegexpOptions: {"strict":true},
    name: "password-recovery-token"
  }, {
    path: "/profile/:id?",
    component: _6b245d08,
    pathToRegexpOptions: {"strict":true},
    name: "profile-id"
  }, {
    path: "/qr/:id?",
    component: _8129d070,
    pathToRegexpOptions: {"strict":true},
    name: "qr-id"
  }, {
    path: "/company/:organization?/product/:id?",
    component: _cb561b82,
    pathToRegexpOptions: {"strict":true},
    name: "company-organization-product-id"
  }, {
    path: "/club/:organization?/:id",
    component: _aca319c0,
    pathToRegexpOptions: {"strict":true},
    name: "club-organization-id"
  }, {
    path: "/company/:organization?/:id",
    component: _b248b742,
    pathToRegexpOptions: {"strict":true},
    name: "company-organization-id"
  }, {
    path: "/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/:organization",
    component: _68dacbc9,
    pathToRegexpOptions: {"strict":true},
    name: "organization"
  }, {
    path: "/:company/event",
    component: _b516c5f6,
    pathToRegexpOptions: {"strict":true},
    name: "company-event"
  }, {
    path: "/:company/invitation",
    component: _cf292684,
    pathToRegexpOptions: {"strict":true},
    name: "company-invitation"
  }, {
    path: "/:organization/product/:id?",
    component: _29676f5e,
    pathToRegexpOptions: {"strict":true},
    name: "organization-product-id"
  }, {
    path: "/:organization/:id",
    component: _61e9a71e,
    pathToRegexpOptions: {"strict":true},
    name: "organization-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
